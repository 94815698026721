.footer{
	padding: 60px 0 30px 0;

	> .container{
		> .row{
			> .row-height{
				> div{
					&.brand{
						text-align: center;

						h6{
							margin: 0 0 10px 0;
							font-size: 0.813em;
							font-weight: 900;
							letter-spacing: 0.07em;
							text-transform: uppercase;
						}
						p{
							font-size: 0.813em;
							line-height: 2;
						}
						a{
							color: $brand-secondary;
						}
						.brand-legal{
							font-size: 0.750em;
							font-weight: 300;

							> span, a{
								margin: 0 5px;
							}
						}
					}
					&.interventi-urgenti{
						margin: 30px 0;
						text-align: center;

						> div{
							display: inline-block;
							margin: 0 auto;

							.icon{
								display: block;
								text-align: right;

								.img-responsive{
									display: inline-block;
								}
							}
							.text{
								display: block;
								font-size: 2.000em;
								font-weight: 900;
								line-height: 1;
								text-align: left;
								text-transform: uppercase;
							}
							.btn{
								display: block;
								padding: 14px;
								margin-top: 15px;
							}
						}
					}
					&.credits{
						text-align: center;

						.brand-social{
							margin: 15px 0;

							> a{
								display: inline-block;
								width: 32px; height: 32px;
								margin: 0 5px;
								font-size: 16px;
								line-height: 30px;
								text-align: center;
								border: $brand-secondary 1px solid;
								@include border-top-radius(100%);
								@include border-bottom-radius(100%);
								color: $brand-secondary;

								&:hover, &:focus{
									text-decoration: none;
								}
								&:hover{
									border-color: $brand-primary;
									color: $brand-primary;
								}
							}
						}
						.credits{
							font-size: 0.688em;
							font-weight: 300;

							a{
								color: $brand-secondary;
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.footer{
		padding: 60px 0;

		> .container{
			> .row{
				> .row-height{
					> div{
						&.brand{
						}
						&.interventi-urgenti{
							margin: 0;
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.footer{
		padding: 60px 0;

		> .container{
			> .row{
				> .row-height{
					> div{
						&.brand{
							text-align: left;

							.brand-legal{
								> span, a{
									margin: 0 10px 0 0;
								}
							}
						}
						&.interventi-urgenti{
							margin: 0;
						}
						&.credits{
							text-align: right;

							> div{
								position: relative;
								height: 100%;

								.brand-social{
									margin: 30px 0;
								}
								.credits{
									position: absolute;
									bottom: 0; right: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}

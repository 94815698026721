.header{
	.navbar-default{
		margin: 0;
		background: url('../images/bg-header.png') top center repeat;
		border: none;
		@include border-top-radius(0);
		@include border-bottom-radius(0);
		transition: top 0.2s ease-in-out;

		&.fixed{
			position: fixed;
			top: 0; bottom: auto;

			-webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);
			box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);
		}
		&.nav-up{
			top: -80px;

			-webkit-box-shadow: none;
			box-shadow: none;
		}
		&.nav-down{
			top: 0;
		}
		.navbar-header{
			position: relative;
			height: 80px;

			.navbar-toggle{
				position: absolute;
				top: 50%; right: 0;
				margin: 0;
				background: none;
				border: none;
				@include border-top-radius(0);
				@include border-bottom-radius(0);
				-ms-transform: translate(0, -50%);
				-webkit-transform: translate(0, -50%);
				transform: translate(0, -50%);

				.icon-bar{
					background: $brand-primary;
				}
				&:hover, &:focus{
					background: none;
					border: none;

					.icon-bar{
						background: $brand-primary;
					}
				}
			}
			.navbar-brand{
				height: auto;
				margin-left: -15px;

				.img-responsive{
					width: 120px;
				}
			}
		}
		.navbar-collapse{
			.navbar-nav{
				> li{
					> a{
						font-size: 0.813em;
						font-weight: 700;
						text-transform: uppercase;
						color: $brand-secondary;

						&:hover, &:focus{
							background: none;
							text-decoration: none;
						}
						&:hover{
							@include opacity(0.75);
						}
					}
					&.open{
						> a{
							background: none;
							color: $brand-secondary;
						}
					}
					&.active{
						> a{
							background: none;
							color: $brand-secondary;

							> span{
								padding-bottom: 5px;
								border-bottom: $brand-secondary 2px solid;
							}
						}
					}
					> ul{
						> li{
							> a{
								font-size: 0.750em;
								font-weight: 700;
								text-transform: uppercase;
								color: $brand-secondary;

								&:hover, &:focus{
									background: none;
									text-decoration: none;
								}
								&:hover{
									@include opacity(0.75);
								}
							}
							&.active{
								> a{
									background: none;
									color: $brand-secondary;

									> span{
										display: inline-block;
										padding-bottom: 5px;
										border-bottom: $brand-secondary 2px solid;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.header{
		.navbar-default{
			background: url('../images/bg-header.png') top center repeat;

			&.nav-up{
				top: -120px;
			}
			> .container{
				position: relative;
				height: 80px;

				> div{
					position: absolute;
					top: 50%; left: 0;
					width: calc(100% - 30px);
					margin: 0 15px;
					background: #fff;

					.navbar-header{
						height: auto;

						.navbar-brand{
							padding: 15px;
							margin: 0;

							.img-responsive{
								width: 100px;
							}
						}
					}
					.navbar-collapse{
						.navbar-nav{
							position: absolute;
							top: 0; right: 8px;

							> li{
								border-left: #E6E6E6 1px solid;

								> a{
									padding: 26px 7px 25px 7px;
									font-size: 0.625em;

									.caret{
										display: none;
									}
								}
								> ul{
									> li{
										> a{
											font-size: 0.625em;
											color: #fff;
										}
										&.active{
											> a{
												> span{
													padding-bottom: 2px;
													border-width: 1px;
													border-color: #fff;
												}
											}
										}
									}
								}
								.dropdown-menu{
									left: 0;
									width: 200px;
									max-width: inherit;
									padding-top: 10px;
									padding-bottom: 10px;
									background: $brand-secondary;
									border: none;
									@include border-top-radius(0);
									@include border-bottom-radius(0);
									box-shadow: none;

									&:before{
										content: '';
										display: block;
										position: absolute;
										top: -7px; left: 10px;
										width: 20px; height: 20px;
										margin-left: 0;
										background: $brand-secondary;
										border-bottom: none;
										@include rotate(45deg);
									}
								}
							}
						}
					}
				}
				.cta-numverde{
					position: absolute;
					top: 6px; left: 30px;
					display: inline-block;
					height: 32px;
					padding-left: 30px;
					font-size: 0.625em;
					font-weight: 700;
					line-height: 1;
					text-transform: uppercase;
					background: url('../images/numverde.png') 0 2px no-repeat;
					background-size: 23px 23px;
					color: $brand-primary;

					> span{
						display: block;
						font-size: 1.800em;
						font-weight: 900;
						line-height: 1;
					}
					&:hover, &:focus{
						text-decoration: none;
					}
					&:hover{
						@include opacity(0.75);
						color: $brand-primary;
					}
				}
				.cta-urgenza{
					position: absolute;
					top: 4px; right: 30px;
					display: inline-block;
					height: 32px;
					padding-right: 40px;
					font-size: 0.938em;
					font-weight: 900;
					line-height: 32px;
					text-transform: uppercase;
					background: url('../images/intervento.png') center right no-repeat;
					background-size: 37px 32px;

					> span{
						color: $brand-primary;
					}
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.header{
		.navbar-default{
			&.nav-up{
				top: -147px;
			}
			> .container{
				height: 98px;

				> div{
					margin: 0 15px;
					
					.navbar-header{
						.navbar-brand{
							.img-responsive{
								width: 136px;
							}
						}
					}
					.navbar-collapse{
						.navbar-nav{
							right: 8px;

							> li{
								> a{
									padding: 33px 7px;
									font-size: 0.813em;
								}
								> ul{
									> li{
										> a{
											font-size: 0.813em;
										}
										&.active{
											> a{
												> span{
													border-width: 1px;
												}
											}
										}
									}
								}
								.dropdown-menu{
									left: 50% !important;
									width: 200px;
									margin-left: -100px;

									&:before{
										left: 50%;
										margin-left: -10px;
									}
								}
							}
						}
					}
				}
				.cta-numverde{
					top: 10px; left: 30px;
				}
				.cta-urgenza{
					top: 8px; right: 30px;
				}
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	.header{
		.navbar-default{
			> .container{
				> div{
					.navbar-header{
						.navbar-brand{
							padding-left: 25px;

							.img-responsive{
								width: 164px;
							}
						}
					}
					.navbar-collapse{
						.navbar-nav{
							right: 10px;

							> li{
								> a{
									padding: 39px 10px;
								}
							}
						}
					}
				}
				.cta-numverde{
					left: 40px;
				}
				.cta-urgenza{
					right: 40px;
				}
			}
		}
	}
}

@media (min-width: 1500px) {
	.header{
		.navbar-default{
			> .container{
				> div{
					.navbar-header{
						.navbar-brand{
							padding-left: 25px
						}
					}
					.navbar-collapse{
						.navbar-nav{
							right: 10px;

							> li{
								> a{
									padding: 39px 20px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
	.header .navbar-default>.container .cta-numverde{
		background-image: url('../images/numverde@2x.png');
	}
	.header .navbar-default>.container .cta-urgenza{
		background-image: url('../images/intervento@2x.png');
	}
}
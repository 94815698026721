.home-carousel{
	.slick{
		position: relative;

		.carousel-item{
			position: relative;
			outline: none;

			&:before{
				content: '';
				position: absolute;
				top: 0; left: 0;
				z-index: 10;
				width: 100%; height: 100%;
				background: -moz-linear-gradient(45deg, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
				background: -webkit-linear-gradient(45deg, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
				background: linear-gradient(45deg, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 );
			}
			.caption{
				position: absolute;
				bottom: 110px; left: 0;
				z-index: 20;
				width: 100%;
				color: #fff;

				.title{
					font-size: 2.500em;
					font-weight: 900;
					text-transform: uppercase;
					letter-spacing: 0.07em;
				} 
				p{
					margin-bottom: 25px;
					font-size: 1.000em;
					font-weight: 900;
					text-transform: uppercase;
					letter-spacing: 0.07em;
				}
			}
			.img-responsive{
				width: 100%;
				max-width: inherit;
			}
		}
		.slick-dots{
			position: absolute;
			bottom: 60px; left: 50%;
			padding: 0;
			margin: 0;
			list-style: none;
			-ms-transform: translate(-50%, 0);
			-webkit-transform: translate(-50%, 0);
			transform: translate(-50%, 0);

			> li{
				display: inline-block;
				outline: none;

				button{
					width: 16px; height: 16px;
					margin: 0 7px;
					overflow: hidden;
					text-indent: -999px;
					background: none;
					border: #fff 2px solid;
					@include border-top-radius(100%);
					@include border-bottom-radius(100%);
					outline: none;
				}
				&.slick-active{
					button{
						background: $brand-primary;
					}
				}
			}
		}
	}
}

.home-plus{
	background: #E9E9E9;

	> .container-fluid{
		> .row{
			> .row-height{
				> div{
					&.text{
						padding-top: 40px;
						padding-bottom: 40px;

						> div{
							padding: 0 15px;

							.subtitle{
								display: block;
								margin: 0 0 15px 0;
								font-size: 1.250em;
								font-weight: 400;
								letter-spacing: 0.07em;
								text-transform: none;
							}
							.title{
								display: block;
								margin: 0 0 30px 0;
								font-size: 2.000em;
								font-weight: 900;
								letter-spacing: 0.07em;
								text-transform: uppercase;
							}
							p{
								letter-spacing: 0.07em;
								line-height: 2;
							}
							a{
								text-decoration: underline;
								color: $brand-secondary;
							}
						}
					}
					&.image{
						padding: 0;

						> div{
							position: relative;
							height: 400px;
							background: transparent center center no-repeat;
							background-size: cover;

							> div{
								position: absolute;
								bottom: 0; left: 0;
								width: 100%;
								padding: 30px;
								background: #575653 url('../images/bg-gray.png') center center repeat;
								color: #fff;

								&:before{
									content: '';
									position: absolute;
									top: 0; left: 0;
									display: block;
									width: 100%; height: 100%;
									background: -moz-linear-gradient(45deg, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
									background: -webkit-linear-gradient(45deg, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
									background: linear-gradient(45deg, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
									filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 );
									@include opacity(0.5);
								}
								> span{
									position: relative;
									z-index: 20;
									display: block;
									font-size: 2.000em;
									font-weight: 900;
									text-transform: uppercase;
								}
								.btn{
									position: relative;
									z-index: 20;
									margin-top: 20px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.home-cats{
	position: relative;
	z-index: 20;
	margin-top: -30px;
	margin-bottom: 30px;

	.item{
		margin-bottom: 30px;

		> a{
			display: block;
			position: relative;
			color: #fff;

			&:hover, &:focus{
				text-decoration: none;
			}
			&:hover{
				.img-responsive{
					@include opacity(1);
				}
			}
			> span{
				position: absolute;
				top: 23px; left: 0;
				z-index: 20;
				width: 40%;
				font-size: 3.750em;
				font-weight: 900;
				text-align: center;
				text-transform: uppercase;
			}
			> div{
				position: absolute;
				bottom: 23px; right: 0;
				z-index: 20;
				width: 100%;
				padding: 0 25px;

				.title{
					margin: 0 0 15px 0;
					font-size: 1.250em;
					font-weight: 900;
					text-transform: uppercase;
				}
				p{
					margin: 0;
					font-size: 0.813em;
				}
			}
			.img-responsive{
				@include opacity(0.4);
			}
		}
		&:nth-child(even){
			> a{
				background: url('../images/bg-gray.png') center center repeat;
			}
		}
		&:nth-child(odd){
			> a{
				background: url('../images/bg-green.png') center center repeat;
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.home-carousel{
		.slick{
			.carousel-item{
				.caption{
					bottom: 60px;
				}
			}
			.slick-dots{
				bottom: 30px;
			}
		}
	}
	.home-plus{
		> .container-fluid{
			> .row{
				> .row-height{
					> div{
						&.text{
						}
						&.image{
							> div{
								height: 100%;
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.home-carousel{
		.slick{
			.carousel-item{
				.caption{
					bottom: 110px;

					.title{
						font-size: 3.000em;
					} 
					p{
						font-size: 1.250em;
					}
				}
			}
			.slick-dots{
				bottom: 60px;
			}
		}
	}
	.home-plus{
		> .container-fluid{
			> .row{
				> .row-height{
					> div{
						&.text{
							padding-top: 75px;
							padding-bottom: 75px;

							> div{
								padding: 0 60px;
							}
						}
						&.image{
							> div{
								> div{
									padding: 45px 60px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	.home-cats{
		.item{
			> a{
				> div{
					width: 60%;
				}
			}
		}
	}
}
